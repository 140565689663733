import React from "react";
import CurrencyConverter from "../../Currency/CurrencyConverter";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
const QuickBalance = ({ tiles, currency, conversionFactor, totalIncome, earnings }) => {
  const { t } = useTranslation();
  const nav = useNavigate();

  const handleTileClick = (activeTab) => {
    nav(`/e-wallet/${activeTab}`)
    console.log('cliiiiiii');
  }

  return (
    <div data-tut="dashboardTiles" className="quick_balance_section">
      <div className="quick_balance_section_row">

        <div className="quick_balance_Box" onClick={() => handleTileClick("statement")}>
          <div className="quick_balance_Box_ico">
            <img src="/images/balance_commision_ico.svg" alt="" />
          </div>
          <div className="quick_balance_Box_cnt">
            <div className="d-flex gap-3">
              <div>
                <span className="d-block">{t("commission")}</span>
                <strong>{`${currency?.symbolLeft ? currency?.symbolLeft : "$"
                  } ${CurrencyConverter(
                    // tiles?.commission,
                    totalIncome,
                    conversionFactor
                  )}`}</strong>
              </div>
              {/* <div
                className={`tp_comparison ${tiles?.commissionSign ? "up" : "down"
                  }`}
              >
                <span>
                  {`${tiles?.commissionSign === "up" ? "+" : "-"}${tiles?.commissionPercentage ?? "0"
                    }%`}
                </span>
                <i
                  className={`fa-solid ${tiles?.commissionSign === "up"
                    ? "fa-arrow-up"
                    : "fa-arrow-down"
                    }`}
                ></i>
              </div> */}
            </div>
          </div>
        </div>

        <div className="quick_balance_Box" onClick={() => handleTileClick("statement")}>
          <div className="quick_balance_Box_ico">
            <img src="/images/balance_ewallet_ico.svg" alt="" />
          </div>
          <div className="quick_balance_Box_cnt">
            <span>{t("eWallet")}</span>
            <strong>{`${currency?.symbolLeft ? currency?.symbolLeft : "$"
              } ${CurrencyConverter(
                tiles?.ewallet ?? 0,
                conversionFactor
              )}`}</strong>
          </div>
        </div>

        <div className="quick_balance_Box" onClick={()=>handleTileClick('purchase_wallet')}>
            <div className="quick_balance_Box_ico">
              <img src="/images/balance_pending_ico.svg" alt="" />
            </div>
            <div className="quick_balance_Box_cnt">
              <div className="d-flex gap-3">
                <div>
                  <span className="d-block">Holding Wallet</span>
                  <strong>{`${currency?.symbolLeft ? currency?.symbolLeft : "$"
                    } ${CurrencyConverter(tiles?.purchaseWallet, conversionFactor)}`}</strong>
                </div>

              </div>
            </div>
          </div>



        {/* <div className="quick_balance_Box" onClick={() => handleTileClick("my_earnings")}>
          <div className="quick_balance_Box_ico">
            <img src="/images/balance_commision_ico.svg" alt="" />
          </div>
          <div className="quick_balance_Box_cnt">
            <div className="d-flex gap-3">
              <div>
                <span className="d-block">{t("totalCredit")}</span>
                <strong>{`${currency?.symbolLeft ? currency?.symbolLeft : "$"
                  } ${CurrencyConverter(
                    tiles?.totalCredit,
                    conversionFactor
                  )}`}</strong>
              </div>
            </div>
          </div>
        </div> */}

        <div className="quick_balance_Box" onClick={() => handleTileClick("statement")}>
          <div className="quick_balance_Box_ico">
            <img src="/images/balance_pending_ico.svg" alt="" />
          </div>
          <div className="quick_balance_Box_cnt">
            <div className="d-flex gap-3">
              <div>
                <span className="d-block">{t("totalDebit")}</span>
                <strong>{`${currency?.symbolLeft ? currency?.symbolLeft : "$"
                  } ${CurrencyConverter(
                    tiles?.totalDebit,
                    conversionFactor
                  )}`}</strong>
              </div>
              {/* <div
                  className={`tp_comparison ${tiles?.debitSign ? "up" : "down"}`}
                >
                  <span>
                    {`${tiles?.debitSign === "up" ? "+" : "-"}${tiles?.totalDebitPercentage ?? "0"
                      }%`}
                  </span>
                  <i
                    className={`fa-solid ${tiles?.debitSign === "up" ? "fa-arrow-up" : "fa-arrow-down"
                      }`}
                  ></i>
                </div> */}
            </div>
          </div>
        </div>




        <div className="quick_balance_Box" onClick={() => handleTileClick("statement")}>
          <div className="quick_balance_Box_ico">
            <img src="/images/balance_pending_ico.svg" alt="" />
          </div>
          <div className="quick_balance_Box_cnt">
            <div className="d-flex gap-3">
              <div>
              <span className="d-block">Referral Income</span>
                <strong>{`${currency?.symbolLeft ? currency?.symbolLeft : "$"
                  } ${CurrencyConverter(
                    earnings?.find(_=>_.amountType === "referral")?.amount,
                    conversionFactor
                  )}`}</strong>
              </div>
            </div>
          </div>
        </div>

        <div className="quick_balance_Box" onClick={() => handleTileClick("statement")}>
          <div className="quick_balance_Box_ico">
            <img src="/images/balance_pending_ico.svg" alt="" />
          </div>
          <div className="quick_balance_Box_cnt">
            <div className="d-flex gap-3">
              <div>
              <span className="d-block">Level Income</span>
                <strong>{`${currency?.symbolLeft ? currency?.symbolLeft : "$"
                  } ${CurrencyConverter(
                    earnings?.find(_=>_.amountType === "level_commission")?.amount,
                    conversionFactor
                  )}`}</strong>
              </div>
            </div>
          </div>
        </div>



        {/* <div className="quick_balance_Box" onClick={() => handleTileClick('rebirth')}>
          <div className="quick_balance_Box_ico">
            <img src="/images/balance_pending_ico.svg" alt="" />
          </div>
          <div className="quick_balance_Box_cnt">
            <div className="d-flex gap-3">
              <div>
                <span className="d-block">Rebirth Accounts</span>
                <strong>{tiles?.rebirthAccounts}</strong>
              </div>

            </div>
          </div>
        </div> */}

      </div>
    </div>
  );
};

export default QuickBalance;
